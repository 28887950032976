
import { FC } from 'react'
import Map from './Map';
import { ContactStation } from '../../../../hooks/useGroundContact';
import { truncateNumber } from '../../../../../CommonComponents/CommonFunctions';
import CustomToolTip from '../../../../../CommonComponents/CustomToolTip';
import { calculate_duration, get_formated_time } from '../../../../utils';
import ToggleMapButton from './ToggleMapButton';
import { MaximizeSvg } from '../Svgs';
interface Props {
    set_show_modal: () => void;
    all_contacts: {
        previous_contact: null | ContactStation,
        ongoing_contact: null | ContactStation,
        upcoming_contact: null | ContactStation
        current_time: null | number,
    };
    selected_contact: {
        contact_type: string;
        contact_data: ContactStation,
    } | null
}
const RightSidePanel: FC<Props> = ({ set_show_modal, all_contacts, selected_contact }) => {
    const { previous_contact, ongoing_contact, upcoming_contact, current_time } = all_contacts

    const get_contact_pass_details = (contact_type, contact, current_time) => {
        const pass_details: {
            time: string;
            duration: string;
            remaining: string
        } = {
            time: '',
            duration: '',
            remaining: '00:00:00'
        }
        if (!contact) return pass_details
        if (contact_type === 'Ongoing') {
            contact.passes.map(pass => {
                if (pass[0] <= current_time && pass[1] >= current_time) {
                    pass_details.time = get_formated_time(pass[0])
                    pass_details.duration = calculate_duration(pass[0], pass[1])
                    pass_details.remaining = get_remaining_time(current_time, pass[1])
                }
            })
        } else if (contact_type === 'Upcoming') {
            const next_passes = contact.passes.filter(pass => pass[0] > current_time)
            if (!next_passes.length) return pass_details
            pass_details.time = get_formated_time(next_passes?.[0]?.[0])
            pass_details.duration = calculate_duration(next_passes?.[0]?.[0], next_passes?.[0]?.[1])
            pass_details.remaining = get_remaining_time(current_time, next_passes?.[0]?.[0])
        } else if (contact_type === 'Previous') {
            const previous_passes = contact.passes.filter(pass => pass[0] < current_time)
            pass_details.time = get_formated_time(previous_passes?.[previous_passes.length - 1]?.[1])
            pass_details.duration = calculate_duration(previous_passes?.[previous_passes.length - 1]?.[0], previous_passes?.[previous_passes.length - 1]?.[1])
            pass_details.remaining = '00:00:00'
        }
        return pass_details
    }

    const get_remaining_time = (start_time, end_time) => {
        const remainingTime = (end_time * 1000) - (start_time * 1000)
        const hours = Math.floor(remainingTime / (1000 * 60 * 60));
        const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

        const paddedHours = hours.toString().padStart(2, '0');
        const paddedMinutes = minutes.toString().padStart(2, '0');
        const paddedSeconds = seconds.toString().padStart(2, '0');
        return paddedHours === '00' ? `${paddedMinutes}:${paddedSeconds}` : `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
    }

    return (
        <div className='ground__container__right__container'>
            <div className='d-flex flex-column gap-2'>
                <div className='contact__details__header'>
                    <span className='contact__details__header__title'>
                        <CustomToolTip title={`${selected_contact?.contact_data?.name}, ${selected_contact?.contact_data?.country}`} placement='bottom'>
                            {selected_contact?.contact_data?.name ? `${selected_contact?.contact_data?.name}, ${selected_contact?.contact_data?.country}` : '--'}
                        </CustomToolTip>
                    </span>
                    <span className='contact__details__header__sub__title'>
                        <CustomToolTip title={`${selected_contact?.contact_data?.provider}`} placement='bottom'>
                            {selected_contact?.contact_data?.provider}
                        </CustomToolTip>
                    </span>
                </div>
                <div className={`contact__status__badge ${selected_contact?.contact_type === 'Ongoing' && 'ongoing'}`}>
                    <span className='badge__title'>{selected_contact?.contact_type}</span>
                    <div className='time__container'>{get_contact_pass_details(selected_contact?.contact_type, selected_contact?.contact_data, current_time).remaining}</div>
                </div>
            </div>
            <div className='contact__visualization__miniview'>
                <Map selected_contact={selected_contact} />
                <ToggleMapButton className='small' onClick={set_show_modal}>
                    <MaximizeSvg />
                </ToggleMapButton>
            </div>
            <div className='ground__contact__details'>
                <span>Contact Time</span>
                <span>{get_contact_pass_details(selected_contact?.contact_type, selected_contact?.contact_data, current_time).time}</span>
            </div>
            <div className='ground__contact__details'>
                <span>Duration</span>
                <span>{get_contact_pass_details(selected_contact?.contact_type, selected_contact?.contact_data, current_time).duration}</span>
            </div>
            <div className='ground__contact__details'>
                <span>Communication Band</span>
                <div className="band">{selected_contact?.contact_data?.['band'].map((band, index) => (
                    <span key={band} className="band">{band}{index !== (selected_contact?.contact_data?.band?.length - 1) && ','}</span>
                ))}</div>
            </div>
            {/* <div className='ground__contact__details'>
                <span>Min. | Max. Elevation</span>
                <span>280 | 900</span>
            </div> */}
            <div className='ground__contact__details__separator' />

            {/* // TODO : // */}
            {/* <div className='ground__contact__task__details__container'>
                <span>Ground Tasks</span>
                <div className='ground__contact__task__details'>
                    <div className='d-flex gap-2'>
                        <TaskCompleteSvg />
                        <span>Telemetry Downlink</span>
                    </div>
                    <span>23 May, 2024 12:10 UTC</span>
                </div>
                <div className='ground__contact__task__details'>
                    <div className='d-flex gap-2'>
                        <TaskOngoingSvg />
                        <span>Status Update</span>
                    </div>
                    <span>23 May, 2024 12:14 UTC</span>
                </div>
                <div className='ground__contact__task__details'>
                    <div className='d-flex gap-2'>
                        <TaskUpcommingSvg />
                        <span>Schedule Upload</span>
                    </div>
                    <span>23 May, 2024 12:17 UTC</span>
                </div>
            </div> */}
            {/* <div className='ground__contact__details__separator' /> */}
            <div className='ground__contact__task__details__container'>
                <span>Contacts</span>
                {upcoming_contact &&
                    <div className={`ground__contact__event__card upcomming`}>
                        <div className='card__left__section'>
                            <span>Upcoming Contact</span>
                        </div>
                        <div className='card__right__section'>
                            <span>{upcoming_contact.name}</span>
                            <span className='time_format'>
                                <CustomToolTip title={get_contact_pass_details('Upcoming', upcoming_contact, current_time)?.time} placement='top'>
                                    {get_contact_pass_details('Upcoming', upcoming_contact, current_time)?.time}
                                </CustomToolTip>
                            </span>
                        </div>
                    </div>
                }
                {ongoing_contact &&
                    <div className={`ground__contact__event__card ongoing`}>
                        <div className='card__left__section'>
                            <span>Ongoing Contact</span>
                        </div>
                        <div className='card__right__section'>
                            <span>{ongoing_contact.name}</span>
                            <span className='time_format'>
                                <CustomToolTip title={`${get_contact_pass_details('Ongoing', ongoing_contact, current_time)?.time}`} placement='top'>
                                    {get_contact_pass_details('Ongoing', ongoing_contact, current_time)?.time}
                                </CustomToolTip>
                            </span>
                        </div>
                    </div>
                }
                {previous_contact &&
                    <div className={`ground__contact__event__card completed`}>
                        <div className='card__left__section'>
                            <span>Previous Contact</span>
                            <div className='contact__status__badge'>Completed</div>
                        </div>
                        <div className='card__right__section'>
                            <span>{previous_contact.name}</span>
                            <span className='time_format'>
                                <CustomToolTip title={get_contact_pass_details('Previous', previous_contact, current_time)?.time} placement='top'>
                                    {get_contact_pass_details('Previous', previous_contact, current_time)?.time}
                                </CustomToolTip>
                            </span>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default RightSidePanel